import { lazy } from "react";

export const Satuan = lazy(() =>
  import("../components/master-data/satuan/general")
);
export const ListSatuan = lazy(() =>
  import("../components/master-data/satuan/list")
);
export const ListJasaPengiriman = lazy(() =>
  import("../components/master-data/jasa-pengiriman/list")
);
export const JasaPengiriman = lazy(() =>
  import("../components/master-data/jasa-pengiriman/general")
);
export const Cabang = lazy(() =>
  import("../components/master-data/cabang/general")
);
export const ListCabang = lazy(() =>
  import("../components/master-data/cabang/list")
);
export const ListSyaratPembayaran = lazy(() =>
  import("../components/master-data/syarat-pembayaran/list")
);
export const SyaratPembayaran = lazy(() =>
  import("../components/master-data/syarat-pembayaran/general")
);
export const Pajak = lazy(() =>
  import("../components/master-data/pajak/general")
);
export const ListPajak = lazy(() =>
  import("../components/master-data/pajak/list")
);
export const ListPenjual = lazy(() =>
  import("../components/master-data/penjual/list")
);
export const Komisi = lazy(() =>
  import("../components/master-data/komisi/general")
);
export const ListKomisi = lazy(() =>
  import("../components/master-data/komisi/list")
);
export const Departemen = lazy(() =>
  import("../components/master-data/departemen/index")
);
export const ListDepartemen = lazy(() =>
  import("../components/master-data/departemen/list")
);
export const Proyek = lazy(() =>
  import("../components/master-data/proyek/index")
);
export const ListProyek = lazy(() =>
  import("../components/master-data/proyek/list")
);
export const ListPemasok = lazy(() =>
  import("../components/master-data/pemasok/list")
);
export const DaftarAkun = lazy(() =>
  import("../components/master-data/daftar-akun/general")
);
export const ListDaftarAkun = lazy(() =>
  import("../components/master-data/daftar-akun/list")
);
export const MataUang = lazy(() =>
  import("../components/master-data/mata-uang/general")
);
export const ListMataUang = lazy(() =>
  import("../components/master-data/mata-uang/list")
);
export const ListPelangganPemasok = lazy(() =>
  import("../components/master-data/pelanggan-pemasok/list")
);
export const PelangganPemasok = lazy(() =>
  import("../components/master-data/pelanggan-pemasok/general")
);
export const ListTipePelanggan = lazy(() =>
  import("../components/master-data/tipe-pelanggan/list")
);
export const TipePelanggan = lazy(() =>
  import("../components/master-data/tipe-pelanggan/general")
);
export const ListPelanggan = lazy(() =>
  import("../components/master-data/pelanggan/list")
);
export const SemuaLaporan = lazy(() => import("../containers/laporan/index"));
export const Gudang = lazy(() =>
  import("../components/master-data/gudang/general")
);
export const ListGudang = lazy(() =>
  import("../components/master-data/gudang/list")
);
export const KategoriBarang = lazy(() =>
  import("../components/master-data/kategori-barang/general")
);
export const ListKategoriBarang = lazy(() =>
  import("../components/master-data/kategori-barang/list")
);
export const TipeAktivaTetapPajak = lazy(() =>
  import("../components/master-data/tipe-aktiva-tetap-pajak/general")
);
export const ListTipeAktivaTetapPajak = lazy(() =>
  import("../components/master-data/tipe-aktiva-tetap-pajak/list")
);
export const TipeAktivaTetap = lazy(() =>
  import("../components/master-data/tipe-aktiva-tetap/general")
);
export const ListTipeAktivaTetap = lazy(() =>
  import("../components/master-data/tipe-aktiva-tetap/list")
);
export const KategoriFormulaProduk = lazy(() =>
  import("../components/master-data/kategori-formula-produk/general")
);
export const ListKategoriFormulaProduk = lazy(() =>
  import("../components/master-data/kategori-formula-produk/list")
);
export const Barang = lazy(() =>
  import("../components/master-data/barang/general")
);
export const ListBarang = lazy(() =>
  import("../components/master-data/barang/list")
);
export const Preferensi = lazy(() =>
  // import("../components/alat-bantu/preferensi")
  import("../containers/alat-bantu/preferensi")
);
export const InformasiPerusahaan = lazy(() =>
  import("../components/menu/informasi-perusahaan")
);

//transaksi

export const FakturPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/faktur_penjualan/general")
);
export const ListFakturPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/faktur_penjualan/list")
);
export const FakturPenagihan = lazy(() =>
  import("../containers/transaction/penjualan/faktur_penagihan/general")
);
export const ListFakturPenagihan = lazy(() =>
  import("../containers/transaction/penjualan/faktur_penagihan/list")
);
export const FakturSementara = lazy(() =>
  import("../containers/transaction/penjualan/faktur_sementara/general")
);
export const ListFakturSementara = lazy(() =>
  import("../containers/transaction/penjualan/faktur_sementara/list")
);
export const PengirimanBarang = lazy(() =>
  import("../containers/transaction/penjualan/pengiriman_barang/general")
);
export const ListPengirimanBarang = lazy(() =>
  import("../containers/transaction/penjualan/pengiriman_barang/list")
);
export const PesananPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/pesanan_penjualan/general")
);
export const ListPesananPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/pesanan_penjualan/list")
);
export const PenawaranPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/penawaran_penjualan/general")
);
export const ListPenawaranPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/penawaran_penjualan/list")
);
export const PenerimaanPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/penerimaan_penjualan/general")
);
export const ListPenerimaanPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/penerimaan_penjualan/list")
);
export const ReturPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/retur_penjualan/general")
);
export const ListReturPenjualan = lazy(() =>
  import("../containers/transaction/penjualan/retur_penjualan/list")
);
export const FakturPembelian = lazy(() =>
  import("../containers/transaction/pembelian/faktur_pembelian/general")
);
export const ListFakturPembelian = lazy(() =>
  import("../containers/transaction/pembelian/faktur_pembelian/list")
);
export const PenerimaanBarang = lazy(() =>
  import("../containers/transaction/pembelian/penerimaan_barang/general")
);
export const ListPenerimaanBarang = lazy(() =>
  import("../containers/transaction/pembelian/penerimaan_barang/list")
);
export const PesananPembelian = lazy(() =>
  import("../containers/transaction/pembelian/pesanan_pembelian/general")
);
export const ListPesananPembelian = lazy(() =>
  import("../containers/transaction/pembelian/pesanan_pembelian/list")
);
export const PermintaanPembelian = lazy(() =>
  import("../containers/transaction/pembelian/permintaan_pembelian/general")
);
export const ListPermintaanPembelian = lazy(() =>
  import("../containers/transaction/pembelian/permintaan_pembelian/list")
);
export const PembayaranPembelian = lazy(() =>
  import("../containers/transaction/pembelian/pembayaran_pembelian/general")
);
export const ListPembayaranPembelian = lazy(() =>
  import("../containers/transaction/pembelian/pembayaran_pembelian/list")
);
export const ReturPembelian = lazy(() =>
  import("../containers/transaction/pembelian/retur_pembelian/general")
);
export const ListReturPembelian = lazy(() =>
  import("../containers/transaction/pembelian/retur_pembelian/list")
);

export const TransaksiBerulang = lazy(() =>
  import("../containers/transaction/recurring/general")
);

export const ListTransaksiBerulang = lazy(() =>
  import("../containers/transaction/recurring/list")
);

//bikin error
// export { default as Pemasok } from "../components/master-data/pemasok/general";
// export {default as Penjual} from '../components/master-data/penjual/general'
// export {default as Pelanggan} from '../components/master-data/pelanggan/general'
export const Pemasok = lazy(() =>
  import("../components/master-data/pemasok/general")
);
export const Penjual = lazy(() =>
  import("../components/master-data/penjual/general")
);
export const Pelanggan = lazy(() =>
  import("../components/master-data/pelanggan/general")
);

export const PelangganDetail = lazy(() =>
  import("../components/master-data/pelanggan/detail/detail")
);
export const PelangganDokumen = lazy(() =>
  import("../components/master-data/pelanggan/dokumen/dokumen")
);
export const PelangganInfo = lazy(() =>
  import("../components/master-data/pelanggan/detail/info")
);
export const PelangganPenjualan = lazy(() =>
  import("../components/master-data/pelanggan/detail/penjualan")
);
export const PelangganSyarat = lazy(() =>
  import("../components/master-data/pelanggan/detail/syarat")
);
export const PelangganKontak = lazy(() =>
  import("../components/master-data/pelanggan/detail/kontak")
);
export const PelangganMemo = lazy(() =>
  import("../components/master-data/pelanggan/detail/memo")
);
export const PelangganBarcode = lazy(() =>
  import("../components/master-data/pelanggan/detail/barcode")
);

//laporan
export const PreviewLaporan = lazy(() =>
  import("../containers/laporan/element/preview-laporan")
);

//auth
export const Login = lazy(() => import("../components/auth/login/Login"));
export const Register = lazy(() =>
  import("../components/auth/register/Register")
);

//user
export const NotFound = lazy(() => import("../components/additional/NotFound"));
export const Create_Databae = lazy(() =>
  import("../components/tenant/Create_Database")
);
export const Dashboard = lazy(() =>
  import("../containers/page/dashboard/Dashboard")
);

//Editor template
export const RancanganFormulir = lazy(() =>
  import("../containers/rancangan-formulir/editor")
);

export const Editor = lazy(() => import("../containers/editor/editor"));

//Buku Besar
export const Jurnal = lazy(() =>
  import("../containers/transaction/bukubesar/jurnal_umum/general")
);

export const ListJurnal = lazy(() =>
  import("../containers/transaction/bukubesar/jurnal_umum/list")
);

export const PembayaranLainnya = lazy(() =>
  import("../containers/transaction/bukubesar/pembayaran_lainnya/general")
);

export const ListPembayaranLainnya = lazy(() =>
  import("../containers/transaction/bukubesar/pembayaran_lainnya/list")
);

export const PenerimaanLainnya = lazy(() =>
  import("../containers/transaction/bukubesar/penerimaan_lainnya/general")
);

export const ListPenerimaanLainnya = lazy(() =>
  import("../containers/transaction/bukubesar/penerimaan_lainnya/list")
);

export const Rekonsiliasi = lazy(() =>
  import("../containers/transaction/bukubesar/rekonsiliasi/general")
);

export const SalinanTransaksi = lazy(() =>
  import("../containers/transaction/bukubesar/salinan_transaksi/general")
);
export const ListSalinanTransaksi = lazy(() =>
  import("../containers/transaction/bukubesar/salinan_transaksi/list")
);

export const AnggaranAkun = lazy(() =>
  import("../containers/transaction/bukubesar/anggaran_akun/general")
);
export const ListAnggaranAkun = lazy(() =>
  import("../containers/transaction/bukubesar/anggaran_akun/list")
);

//persediaan
export const PenyesuaianBarang = lazy(() =>
  import("../containers/transaction/persediaan/penyesuaian-barang/general")
);
export const ListPenyesuaianBarang = lazy(() =>
  import("../containers/transaction/persediaan/penyesuaian-barang/list")
);
export const PembiayaanPesanan = lazy(() =>
  import("../containers/transaction/persediaan/pembiayaan-pesanan/general")
);
export const ListPembiayaanPesanan = lazy(() =>
  import("../containers/transaction/persediaan/pembiayaan-pesanan/list")
);
export const PindahBarang = lazy(() =>
  import("../containers/transaction/persediaan/pindah-barang/general")
);
export const ListPindahBarang = lazy(() =>
  import("../containers/transaction/persediaan/pindah-barang/list")
);
export const PengelompokanBarang = lazy(() =>
  import("../containers/transaction/persediaan/pengelompokan-barang/general")
);
export const ListPengelompokanBarang = lazy(() =>
  import("../containers/transaction/persediaan/pengelompokan-barang/list")
);
export const AuditPengelompokanBarang = lazy(() =>
  import("../containers/transaction/persediaan/pengelompokan-barang/audit")
);
export const PencatatanNomorSerial = lazy(() =>
  import("../containers/transaction/persediaan/pencatatan-no-serial/general")
);
export const ListPencatatanNomorSerial = lazy(() =>
  import("../containers/transaction/persediaan/pencatatan-no-serial/list")
);
export const HargaJual = lazy(() =>
  import("../containers/transaction/persediaan/harga-jual/general")
);
export const ListHargaJual = lazy(() =>
  import("../containers/transaction/persediaan/harga-jual/list")
);
//Aktiva

export const AktivaTetap = lazy(() =>
  import("../containers/transaction/aktiva/general")
);

export const ListAktivaTetap = lazy(() =>
  import("../containers/transaction/aktiva/list")
);

//catatan persetujuan
export const Approval = lazy(() => import("../containers/approval/general"));
export const ListApproval = lazy(() => import("../containers/approval/list"));
export const Otorisasi = lazy(() => import("../containers/otorisasi/general"));
export const ListOtorisasi = lazy(() => import("../containers/otorisasi/list"));
//Manufaktur
export const FormulaProduk = lazy(() =>
  import("../containers/transaction/manufaktur/formula-produk/general")
);
export const ListFormulaProduk = lazy(() =>
  import("../containers/transaction/manufaktur/formula-produk/list")
);
export const PenanggungJawabProduksi = lazy(() =>
  import("../components/master-data/PenanggungJawabProduksi/general")
);
export const ListPenanggungJawabProduksi = lazy(() =>
  import("../components/master-data/PenanggungJawabProduksi/list")
);
export const BiayaProduksi = lazy(() =>
  import("../components/master-data/biaya-produksi/general")
);
export const ListBiayaProduksi = lazy(() =>
  import("../components/master-data/biaya-produksi/list")
);
export const PerintahKerja = lazy(() =>
  import("../containers/transaction/manufaktur/perintah-kerja/general")
);
export const ListPerintahKerja = lazy(() =>
  import("../containers/transaction/manufaktur/perintah-kerja/list")
);
export const PengeluaranBahanBaku = lazy(() =>
  import("../containers/transaction/manufaktur/pengeluaran-bahan-baku/general")
);
export const ListPengeluaranBahanBaku = lazy(() =>
  import("../containers/transaction/manufaktur/pengeluaran-bahan-baku/list")
);
export const HasilProduksi = lazy(() =>
  import("../containers/transaction/manufaktur/hasil-produksi/general")
);
export const ListHasilProduksi = lazy(() =>
  import("../containers/transaction/manufaktur/hasil-produksi/list")
);
export const StandarisasiBiayaProduk = lazy(() =>
  import("../components/master-data/standarisasi-biaya-produk/general")
);
export const ListStandarisasiBiayaProduk = lazy(() =>
  import("../components/master-data/standarisasi-biaya-produk/list")
);

export const StandarisasiHargaBarang = lazy(() =>
  import("../components/master-data/standarisasi-harga-barang/general")
);

export const ListStandarisasiHargaBarang = lazy(() =>
  import("../components/master-data/standarisasi-harga-barang/list")
);

export const LihatJurnal = lazy(() =>
  import("../components/transaction/jurnal")
);

export const RiwayatPersediaan = lazy(() =>
  import("../components/transaction/riwayat-persediaan")
);
export const RiwayatAkun = lazy(() =>
  import("../components/transaction/riwayat-akun")
);
export const BarangPerGudang = lazy(() =>
  import("../components/transaction/barang-per-gudang")
);
export const SaldoAkun = lazy(() =>
  import("../components/transaction/saldo-akun")
);
export const RiwayatBank = lazy(() =>
  import("../components/transaction/riwayat-bank")
);
export const RiwayatPelangganPemasok = lazy(() =>
  import("../components/transaction/riwayatPelangganPemasok")
);

// pratinjau
export const Pratinjau = lazy(() =>
  import("../components/transaction/pratinjau")
);

// export { default as Pratinjau } from "../components/transaction/pratinjau"

export const designPreview = lazy(() =>
  import("../components/laporan/rancangan_formulir/designPreview")
);

//verification sign up
export const Verification = lazy(() =>
  import("../containers/Auth/verification")
);

export const ForgotPassword = lazy(() =>
  import("../containers/Auth/forgot-password")
);

export const NewPassword = lazy(() =>
  import("../containers/Auth/New-Password")
);

//import
export const ImportPreference = lazy(() =>
  import("../components/preferensi-database/import")
);
export const ImportJurnalUmum = lazy(() =>
  import("./../containers/import/BukuBesar/jurnal-umum")
);
export const ImportPesananPenjualan = lazy(() =>
  import("../containers/import/penjualan/pesanan_penjualan")
);
export const ImportPengirimanBarang = lazy(() =>
  import("../containers/import/penjualan/pengiriman_barang")
);
export const ImportFakturPenjualan = lazy(() =>
  import("../containers/import/penjualan/faktur_penjualan")
);
export const ImportPermintaanPembelian = lazy(() =>
  import("../containers/import/Pembelian/permintaan_pembelian")
);
export const ImportPesananPembelian = lazy(() =>
  import("../containers/import/Pembelian/pesanan_pembelian")
);
export const ImportPenerimaanBarang = lazy(() =>
  import("../containers/import/Pembelian/penerimaan_barang")
);
export const ImportFakturPembelian = lazy(() =>
  import("../containers/import/Pembelian/faktur_pembelian")
);
export const ImportDaftarAkun = lazy(() =>
  import("../containers/import/daftar-akun")
);
export const ImportDaftarPelanggan = lazy(() =>
  import("../containers/import/daftar-pelanggan")
);
export const ImportDaftarPemasok = lazy(() =>
  import("../containers/import/daftar-pemasok")
);
export const ImportDaftarBarangJasa = lazy(() =>
  import("../containers/import/daftar-barang-jasa")
);
export const ImportAktivaTetap = lazy(() =>
  import("../containers/import/AktivaTetap/AktivaTetap")
);

export const Billing = lazy(() => import("../containers/billing/index"));
export const Aktifasi = lazy(() => import("../containers/aktifasi/index"));
export const RegistrasiEPS = lazy(() => import("../containers/registrasi-eps"));
//admin
export const AdminPage = lazy(() => import("../containers/admin/index"));

//audit
export const Audit = lazy(() => import("../components/transaction/audit"));
// export { default as Audit } from "../components/transaction/audit";
export const Audit1 = lazy(() => import("../components/transaction/audit1"));
export const Audit3 = lazy(() => import("../components/transaction/audit3"));

//audit faktur pembelian
export const AuditFakturPembelian = lazy(() =>
  import("../containers/transaction/pembelian/faktur_pembelian/audit")
);

//Connect and tracking tracking
export const TemplateEmail = lazy(() =>
  import("../containers/transaction/connectandtracking/TemplateEmail/general")
);

export const ListTemplateEmail = lazy(() =>
  import("../containers/transaction/connectandtracking/TemplateEmail/list")
);

export const StatusCNT = lazy(() =>
  import("../containers/transaction/connectandtracking/StatusCNT/list")
);
export const ListInvitation = lazy(() =>
  import("../containers/transaction/connectandtracking/Invitation/list")
);
export const Invitation = lazy(() =>
  import("../containers/transaction/connectandtracking/Invitation/general")
);
//chat
export const Chat = lazy(() => import("../containers/chat"));
//scheduling sales
export const ListTipeActivity = lazy(() =>
  import("../containers/transaction/connectandtracking/TipeActivity/list")
);
export const TipeActivity = lazy(() =>
  import("../containers/transaction/connectandtracking/TipeActivity/general")
);
export const ListSchedulingSales = lazy(() =>
  import("../containers/transaction/connectandtracking/SchedulingSales/list")
);
export const SchedulingSales = lazy(() =>
  import("../containers/transaction/connectandtracking/SchedulingSales/general")
);
export const ListSalesmanActivity = lazy(() =>
  import("../containers/transaction/connectandtracking/SalesmanActivity/list")
);
export const SalesmanActivity = lazy(() =>
  import(
    "../containers/transaction/connectandtracking/SalesmanActivity/general"
  )
);
